/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type IntlService from 'embercom/services/intl';
import { type FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import { put } from 'embercom/lib/ajax';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
import { taskFor } from 'ember-concurrency-ts';
import type RouterService from '@ember/routing/router-service';
import type finTrialService from 'embercom/services/fin-trial-service';
import type Profile from 'embercom/models/ai-agent/profile';

interface Args {
  previewConfiguration?: $TSFixMe;
  hasContentReadyForFin: boolean;
  channelType: ChannelType;
  hasOverridableWorkflows?: boolean;
  finSetupRuleset: $TSFixMe;
  reloadSetupRuleset: (setupType: ChannelType) => void;
  updateRuleset: (ruleset: $TSFixMe) => void;
  canSetFinLive: boolean;
  cannotSetFinLiveReason?: string;
  isFinSetupLive: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export const FIN_SETUP_BEHAVIOR_ENDPOINT = '/ember/operator_workflows/fin_setup_behavior';

export default class Settings extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare router: RouterService;
  @service declare finOptInService: FinOptInService;
  @service declare finTrialService: finTrialService;

  @tracked openSectionId = '';

  get isMessengerInstalled() {
    return this.guideLibraryService.isMessengerInstalled;
  }

  get emailSetupValid() {
    let result = this.aiAgentSetupService.validateEmailSetup;
    return result.isValid;
  }

  get triggerName() {
    switch (this.args.channelType) {
      case 'email':
        return this.intl.t('operator.fin.setup.setup-and-go-live.customer-sends-first-message');
      case 'phone':
        return this.intl.t('operator.fin.setup.setup-and-go-live.customer-starts-new-phone-call');
      default:
        return this.intl.t('operator.fin.setup.setup-and-go-live.customer-starts-new-conversation');
    }
  }

  get triggerIcon(): InterfaceIconName {
    switch (this.args.channelType) {
      case 'email':
        return 'manual-message';
      case 'phone':
        return 'phone';
      default:
        return 'wave';
    }
  }

  get shouldShowFinOverrideMessage(): boolean {
    return !!this.otherAutomationsText;
  }

  get workflowsUrl(): string {
    return this.router.urlFor('apps.app.automation.workflows-overview', {
      queryParams: { hasFin: true, state: 1 },
    });
  }

  get profilesUrl(): string {
    return this.router.urlFor('apps.app.automation.fin-ai-agent.profiles');
  }

  get otherAutomationsText(): string | undefined {
    return this.args.channelType === 'email' ? this.emailOverrideMessage : this.chatOverrideMessage;
  }

  get emailOverrideMessage(): string | undefined {
    if (!this.args.hasOverridableWorkflows) {
      return;
    }

    return this.intl.t('operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.email', {
      workflowsUrl: this.workflowsUrl,
      htmlSafe: true,
    });
  }

  get workflowsAndProfilesLink(): string {
    return this.intl.t(
      'operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.chat.profiles-and-workflows',
      {
        workflowsUrl: this.workflowsUrl,
        profilesUrl: this.profilesUrl,
        htmlSafe: true,
      },
    );
  }

  get workflowsLink(): string {
    return this.intl.t(
      'operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.chat.workflows',
      {
        workflowsUrl: this.workflowsUrl,
        htmlSafe: true,
      },
    );
  }

  get profilesLink(): string {
    return this.intl.t(
      'operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.chat.profiles',
      {
        profilesUrl: this.profilesUrl,
        htmlSafe: true,
      },
    );
  }

  get chatOverrideMessage(): string | undefined {
    let hasProfiles = this.aiAgentSetupService.finStats.liveFinProfilesCount > 0;

    if (!this.args.hasOverridableWorkflows && !hasProfiles) {
      return;
    }

    if (this.args.hasOverridableWorkflows && hasProfiles) {
      return this.workflowsAndProfilesLink;
    } else if (this.args.hasOverridableWorkflows) {
      return this.workflowsLink;
    } else {
      return this.profilesLink;
    }
  }

  get contentPageUrl(): string {
    return this.router.urlFor('apps.app.automation.fin-ai-agent.setup', {
      queryParams: {
        activeTab: 'add-content',
      },
    });
  }

  get profile(): Profile {
    return this.args.finSetupRuleset.rulesetLinks.firstObject.object;
  }

  @task({ drop: true })
  *setFinLive() {
    try {
      let ruleset = this.args.finSetupRuleset;
      if (!ruleset) {
        return;
      }

      // to get here, customer has seen the Fin AI Agent overview landing page with all the terms for using Fin
      if (ruleset.isDraft && !this.finOptInService.hasOptedInToFin) {
        yield taskFor(this.finOptInService.postAcceptOptIn).perform();
      }

      yield ruleset.activate();

      this.finTrialService.openFinRetrialConfirmationModal();

      this.args.reloadSetupRuleset(this.args.channelType);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.failure',
        ),
      });
    }
  }

  @task({ drop: true })
  *pauseFin() {
    try {
      let ruleset = this.args.finSetupRuleset;
      if (!ruleset) {
        return;
      }

      yield ruleset.deactivate();
      this.args.reloadSetupRuleset(this.args.channelType);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.pause-success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.pause-failure',
        ),
      });
    }
  }

  partialSave = async (params: FinSetupBehaviorSaveParams) => {
    let baseParams = {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      setup_type: this.args.channelType,
    };
    try {
      let ruleset = await put(FIN_SETUP_BEHAVIOR_ENDPOINT, { ...baseParams, ...params });
      this.args.updateRuleset(ruleset);
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.fin.setup.setup-and-go-live.notification-messages.success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('operator.fin.setup.setup-and-go-live.notification-messages.failure'),
      });
    }
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Settings': typeof Settings;
  }
}
