/* RESPONSIBLE TEAM: team-frontend-tech */
import { type ComponentLike } from '@glint/template';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import RouteRegexes from 'embercom/lib/route-regexes';
import MissingContactInfoAlert from 'embercom/components/new-settings/workspace/security/missing-contact-info-alert';
import NewHelpCenterOption from 'embercom/components/new-settings/helpcenter/new-help-center-option';
import DefaultHelpCenterOption from '../helpcenter/default-help-center-option';
import NewPricingOption from 'embercom/components/new-settings/workspace/new-pricing-option';
import AppStoreOption from 'embercom/components/new-settings/app-settings/app-store-option';

type NewHelpCenterOptionProps = {
  itemTitle: string;
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
};

type DefaultHelpCenterOptionProps = {
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
};

export interface NavItem<T = any> {
  icon: InterfaceIconName;
  key: string;
  route?: string;
  gaRoute?: string;
  routeWithParams?: boolean;
  params?: Record<string, string>;
  routeRegex?: string | RegExp;
  titleIconComponent?: ComponentLike;
  hideOnFeatureFlag?: string;
  showOnFeatureFlag?: string;
  model?: $TSFixMe;
  customRenderer?: ComponentLike<T>;
  customDisplayCondition?: (app: $TSFixMe) => boolean;
  showForStandaloneApps: boolean;
  shouldOnlyShowForStandaloneApps?: boolean;
  navigableCondition?: (customer: $TSFixMe) => boolean;
  unnavigableTranslationKey?: string | undefined;
  externalLink?: boolean;
}

export interface NavSection {
  keyword: string;
  routeRegex: string | RegExp;
  hasNoChildren: boolean;
  items: NavItem[];
  route: string;
  showForStandaloneApps: boolean;
  showOnlyOnFeatureFlag?: string;
  customDisplayCondition?: (app: $TSFixMe) => boolean;
}

const WORKSPACE_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'long-text',
    key: 'general',
    route: 'apps.app.settings.workspace.general',
    showForStandaloneApps: true,
  },
  {
    icon: 'person-square-fill',
    key: 'teammates',
    route: 'apps.app.settings.workspace.teammates',
    showForStandaloneApps: true,
  },
  {
    icon: 'clock-filled',
    key: 'office-hours',
    route: 'apps.app.settings.workspace.office-hours',
    showForStandaloneApps: true,
  },
  {
    icon: 'brands',
    key: 'brands',
    route: 'apps.app.settings.workspace.brands',
    showOnFeatureFlag: 'multibrand-email-open-beta',
    showForStandaloneApps: false,
  },
  {
    icon: 'qual-identify',
    key: 'security',
    route: 'apps.app.settings.workspace.security',
    titleIconComponent: MissingContactInfoAlert,
    showForStandaloneApps: true,
  },
  {
    icon: 'gift',
    key: 'referrals',
    route: 'apps.app.settings.workspace.referrals',
    showOnFeatureFlag: 'team-purchase-experience-referrals',
    showForStandaloneApps: false,
  },
];

const showBillingUsageItem = (app: $TSFixMe) => {
  return app.onFinForPlatformsOrPricing5 && app.hasActiveSubscription;
};

const navigableCondition = (customer: $TSFixMe) => {
  return !customer.inTrial;
};

const SUBSCRIPTION_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'creditcard',
    key: 'billing',
    route: 'apps.app.settings.subscription.billing.index',
    // The index route will redirect to the correct sub-route based on the customer's state
    // So we use the regex here to match multiple possible routes
    routeRegex:
      /^apps\.app\.settings\.subscription\.billing\.(loading|index|summary|details|invoices|settings|manage-subscription|update-subscription)/,
    customDisplayCondition: (app: $TSFixMe) => {
      return !app.canUseBillingSummaryRedesign;
    },
    showForStandaloneApps: true,
  },
  {
    icon: 'creditcard',
    key: 'billing',
    route: 'apps.app.settings.subscription.billing.subscription-details',
    routeRegex:
      /^apps\.app\.settings\.subscription\.billing\.(invoices|settings|manage-subscription|update-subscription|subscription-details)/,
    customDisplayCondition: (app: $TSFixMe) => {
      return app.canUseBillingSummaryRedesign && app.inTrial;
    },
    showForStandaloneApps: false,
  },
  {
    icon: 'creditcard',
    key: 'billing',
    route: 'apps.app.settings.subscription.billing.current-billing-period-charges',
    routeRegex:
      /^apps\.app\.settings\.subscription\.billing\.(current-billing-period-charges|invoices|settings|manage-subscription|update-subscription|subscription-details)/,
    customDisplayCondition: (app: $TSFixMe) => {
      return app.canUseBillingSummaryRedesign && !app.inTrial;
    },
    showForStandaloneApps: false,
  },
  {
    icon: 'column-chart',
    key: 'usage',
    route: 'apps.app.settings.subscription.usage',
    customDisplayCondition: (app: $TSFixMe) => {
      return showBillingUsageItem(app);
    },
    navigableCondition,
    unnavigableTranslationKey: 'new-settings.submenu.subscription.usage-unavailable',
    showForStandaloneApps: true,
  },
  {
    icon: 'column-chart',
    key: 'manage-usage',
    route: 'apps.app.settings.subscription.billing.manage-usage',
    customDisplayCondition: (app: $TSFixMe) => {
      return !showBillingUsageItem(app);
    },
    navigableCondition,
    unnavigableTranslationKey: 'new-settings.submenu.subscription.usage-unavailable',
    showForStandaloneApps: false,
  },
  {
    icon: 'stars',
    key: 'new-pricing',
    customRenderer: NewPricingOption,
    showForStandaloneApps: false,
  },
];

const HELPDESK_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'multiple-people',
    key: 'team-inboxes',
    route: 'apps.app.settings.helpdesk.teams',
    customDisplayCondition: () => true,
    showForStandaloneApps: false,
  },
  {
    icon: 'multiple-people',
    key: 'teams',
    route: 'apps.app.settings.helpdesk.teams',
    customDisplayCondition: () => false,
    showForStandaloneApps: false,
  },
  {
    icon: 'assignment',
    key: 'assignments',
    route: 'apps.app.settings.helpdesk.assignments',
    customDisplayCondition: () => false,
    showForStandaloneApps: false,
  },
  {
    icon: 'assignment',
    key: 'assignments-inbox',
    route: 'apps.app.settings.helpdesk.assignments',
    customDisplayCondition: () => true,
    showForStandaloneApps: false,
  },
  {
    icon: 'saved-reply',
    key: 'macros',
    route: 'apps.app.settings.helpdesk.macros',
    showForStandaloneApps: false,
  },
  {
    icon: 'ticket',
    key: 'tickets',
    route: 'apps.app.settings.helpdesk.tickets',
    showForStandaloneApps: false,
  },
  {
    icon: 'sla',
    key: 'sla',
    route: 'apps.app.settings.helpdesk.sla',
    customDisplayCondition: () => true,
    showForStandaloneApps: false,
  },
  {
    icon: 'rules',
    key: 'rules',
    route: 'apps.app.settings.helpdesk.rules',
    hideOnFeatureFlag: 'workflows-net-new-experience',
    showForStandaloneApps: false,
  },
];

const AI_AUTOMATION_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'rounded-check',
    key: 'external-ai',
    route: 'apps.app.settings.ai-automation.external-ai',
    showForStandaloneApps: false,
    showOnFeatureFlag: 'answerbot-ai-global-opt-out',
  },
  {
    icon: 'ai',
    key: 'fin-copilot-helpdesk-ai',
    route: 'apps.app.settings.ai-automation.helpdesk-ai',
    customDisplayCondition: (app: $TSFixMe) => !!app.canAccessAiAssistBeta,
    showForStandaloneApps: false,
  },
  {
    icon: 'auto-message',
    key: 'automation',
    route: 'apps.app.settings.ai-automation.automation',
    showForStandaloneApps: false,
  },
];

const STANDALONE_AI_AUTOMATION_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'language',
    key: 'fin-reply-settings',
    route: 'apps.app.settings.ai-automation.fin-ai-agent',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
  },
  {
    icon: 'lwr-happy',
    key: 'fin-personality',
    route: 'apps.app.standalone.personality',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
  },
];

const APPS_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'app-store',
    key: 'browse-all-apps',
    customRenderer: AppStoreOption,
    showForStandaloneApps: false,
  },
  {
    icon: 'zendesk',
    key: 'standalone-zendesk-integration',
    route: 'apps.app.settings.standalone.integration',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
    customDisplayCondition: (app: $TSFixMe) => app.hasStandalonePlatform('zendesk'),
  },
  {
    icon: 'salesforce',
    key: 'standalone-salesforce-integration',
    route: 'apps.app.settings.standalone.integration',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
    customDisplayCondition: (app: $TSFixMe) => app.hasStandalonePlatform('salesforce'),
  },
  {
    icon: 'webhook',
    key: 'custom-actions',
    route: 'apps.app.settings.app-settings.custom-actions',
    showForStandaloneApps: true,
  },
  {
    icon: 'webhook',
    key: 'legacy-webhooks',
    route: 'apps.app.settings.app-settings.legacy-webhooks',
    showOnFeatureFlag: 'team-product-exploration-ia-settings-ga',
    showForStandaloneApps: false,
  },
  {
    icon: 'multiplatform',
    key: 'authentication',
    route: 'apps.app.settings.app-settings.authentication',
    showForStandaloneApps: false,
  },
  {
    icon: 'code',
    key: 'developer-hub',
    route: 'apps.app.developer-hub',
    externalLink: true,
    showForStandaloneApps: false,
  },
  {
    icon: 'multiplatform',
    key: 'standalone-authentication',
    route: 'apps.app.settings.app-settings.authentication',
    showOnFeatureFlag: 'answerbot-fin-actions-workflow-write-actions',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
  },
];

// Generate a list of subnav items for data with tags people companies conversation-tickets custom-objects imports-exports as a key in the objects
export const DATA_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'tag',
    key: 'tags',
    route: 'apps.app.settings.data.tags',
    showForStandaloneApps: true,
  },
  {
    icon: 'recipient-data',
    key: 'people',
    route: 'apps.app.settings.data.people',
    params: { tab: 'attributes' },
    showForStandaloneApps: false,
  },
  {
    icon: 'recipient-data',
    key: 'standalone-people',
    route: 'apps.app.settings.standalone.data.people',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
  },
  {
    icon: 'multiple-people',
    key: 'audiences',
    route: 'apps.app.settings.data.audiences',
    showForStandaloneApps: true,
  },
  {
    icon: 'all-companies',
    key: 'companies',
    route: 'apps.app.settings.data.companies',
    params: { tab: 'attributes' },
    showForStandaloneApps: false,
  },
  {
    icon: 'all-companies',
    key: 'standalone-companies',
    route: 'apps.app.settings.standalone.data.companies',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
  },
  {
    icon: 'attribute-titles',
    key: 'conversation-tickets',
    route: 'apps.app.settings.data.conversation-tickets',
    showForStandaloneApps: false,
  },
  {
    icon: 'attribute-titles',
    key: 'standalone-conversation-tickets',
    route: 'apps.app.settings.data.conversation-tickets',
    showForStandaloneApps: true,
    shouldOnlyShowForStandaloneApps: true,
  },
  {
    icon: 'custom-objects',
    key: 'custom-objects',
    route: 'apps.app.settings.data.custom-objects',
    showForStandaloneApps: false,
  },
  {
    icon: 'export',
    key: 'imports-exports',
    route: 'apps.app.settings.data.imports-exports.index',
    params: { tab: 'import-zendesk' },
    showForStandaloneApps: false,
  },
];

export const PROACTIVE_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'sessions',
    key: 'subscriptions',
    route: 'apps.app.settings.proactive-support.subscriptions',
    gaRoute: 'apps.app.settings.proactive-support.subscriptions',
    showForStandaloneApps: false,
  },
  {
    icon: 'news-filled',
    key: 'newsfeeds',
    route: 'apps.app.settings.proactive-support.newsfeeds',
    gaRoute: 'apps.app.settings.proactive-support.newsfeeds',
    showForStandaloneApps: false,
  },
  {
    icon: 'label',
    key: 'news-labels',
    route: 'apps.app.settings.proactive-support.news-labels',
    gaRoute: 'apps.app.settings.proactive-support.news-labels',
    showForStandaloneApps: false,
  },
  {
    icon: 'gallery',
    key: 'customization',
    route: 'apps.app.settings.proactive-support.customization',
    gaRoute: 'apps.app.settings.proactive-support.customization',
    showForStandaloneApps: false,
  },
];

export const HELPCENTER_SUBNAV_ITEMS: NavItem<
  NewHelpCenterOptionProps | DefaultHelpCenterOptionProps
>[] = [
  {
    icon: 'article',
    key: 'workspace-helpcenter',
    customRenderer: DefaultHelpCenterOption,
    showForStandaloneApps: false,
  },
  {
    icon: 'gallery',
    key: 'all-help-centers',
    route: 'apps.app.settings.helpcenter.all',
    showOnFeatureFlag: 'team-product-exploration-ia-settings-ga',
    showForStandaloneApps: false,
  },
  {
    icon: 'new',
    key: 'new-help-center',
    showOnFeatureFlag: 'team-product-exploration-ia-settings-ga',
    customRenderer: NewHelpCenterOption,
    showForStandaloneApps: false,
  },
];

const CHANNELS_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'messenger',
    key: 'messenger',
    route: 'apps.app.settings.channels.messenger.web',
    params: { tab: 'content' },
    routeRegex: RouteRegexes.newSettings.messenger.root,
    showForStandaloneApps: false,
  },
  {
    icon: 'messenger',
    key: 'intercom-messenger',
    route: 'apps.app.settings.channels.messenger.web',
    params: { tab: 'content', section: 'layout-and-spaces' },
    routeRegex: RouteRegexes.newSettings.messenger.root,
    showForStandaloneApps: true,
    customDisplayCondition: (app: { isStandaloneApp: boolean }) => app.isStandaloneApp,
  },
  {
    icon: 'email',
    key: 'email',
    route: 'apps.app.settings.channels.email',
    params: { tab: 'email-forwarding' },
    showForStandaloneApps: false,
  },
  {
    icon: 'phone',
    key: 'phone',
    route: 'apps.app.settings.channels.phone',
    showForStandaloneApps: false,
  },
  {
    icon: 'whatsapp',
    key: 'whatsapp',
    route: 'apps.app.settings.channels.whatsapp',
    showForStandaloneApps: false,
  },
  {
    icon: 'switch',
    key: 'switch',
    route: 'apps.app.settings.channels.switch',
    showForStandaloneApps: false,
  },
  {
    icon: 'slack',
    key: 'slack',
    route: 'apps.app.settings.channels.slack',
    showForStandaloneApps: false,
    showOnFeatureFlag: 'team-messenger-slack-channel',
  },
  {
    icon: 'sms',
    key: 'sms',
    route: 'apps.app.settings.channels.sms',
    showForStandaloneApps: false,
  },
  {
    icon: 'app-store',
    key: 'social-channels-v2',
    route: 'apps.app.settings.channels.social-channels',
    params: { tab: 'facebook' },
    showForStandaloneApps: false,
  },
  {
    icon: 'long-text',
    key: 'all',
    route: 'apps.app.settings.channels.all',
    showForStandaloneApps: false,
  },
];

const PERSONAL_SUBNAV_ITEMS: NavItem[] = [
  {
    icon: 'long-text',
    key: 'details',
    route: 'apps.app.settings.personal.details',
    showForStandaloneApps: false,
  },
  {
    icon: 'owner',
    key: 'preferences',
    route: 'apps.app.settings.personal.preferences',
    showForStandaloneApps: false,
  },
  {
    icon: 'notification',
    key: 'notifications',
    route: 'apps.app.settings.personal.notifications',
    showForStandaloneApps: false,
  },
  {
    icon: 'visible',
    key: 'visible-to-you',
    route: 'apps.app.settings.personal.visible-to-you',
    showForStandaloneApps: false,
  },
  {
    icon: 'code-block',
    key: 'authentication',
    route: 'apps.app.settings.personal.authentication',
    showForStandaloneApps: false,
  },
  {
    icon: 'screenshare',
    key: 'account-access',
    route: 'apps.app.settings.personal.account-access',
    showForStandaloneApps: false,
  },
];

export const SETTINGS_NAV_ITEMS: NavSection[] = [
  {
    keyword: 'home',
    routeRegex: RouteRegexes.newSettings.home,
    hasNoChildren: true,
    items: [],
    route: 'apps.app.settings.index',
    showForStandaloneApps: true,
  },
  {
    keyword: 'workspace',
    routeRegex: RouteRegexes.newSettings.workspace,
    hasNoChildren: false,
    items: WORKSPACE_SUBNAV_ITEMS,
    route: 'apps.app.settings.workspace.general',
    showForStandaloneApps: true,
  },
  {
    keyword: 'subscription',
    routeRegex: RouteRegexes.newSettings.subscription,
    hasNoChildren: false,
    items: SUBSCRIPTION_SUBNAV_ITEMS,
    route: 'apps.app.settings.subscription.billing',
    showForStandaloneApps: true,
  },
  {
    keyword: 'channels',
    routeRegex: RouteRegexes.newSettings.channels,
    hasNoChildren: false,
    items: CHANNELS_SUBNAV_ITEMS,
    route: 'apps.app.settings.channels.messenger',
    showForStandaloneApps: true,
  },
  {
    keyword: 'helpdesk',
    routeRegex: RouteRegexes.newSettings.helpdesk,
    hasNoChildren: false,
    items: HELPDESK_SUBNAV_ITEMS,
    route: 'apps.app.settings.helpdesk.teams',
    showForStandaloneApps: false,
  },
  {
    keyword: 'ai-automation',
    routeRegex: RouteRegexes.newSettings.aiAutomation,
    hasNoChildren: false,
    items: AI_AUTOMATION_SUBNAV_ITEMS,
    route: 'apps.app.settings.ai-automation.fin-ai-agent',
    showForStandaloneApps: false,
  },
  {
    keyword: 'ai-automation-for-standalone',
    routeRegex: RouteRegexes.newSettings.aiAutomation,
    hasNoChildren: false,
    items: STANDALONE_AI_AUTOMATION_SUBNAV_ITEMS,
    route: 'apps.app.settings.ai-automation.fin-ai-agent',
    showForStandaloneApps: true,
    customDisplayCondition: (app: $TSFixMe) => app.isStandaloneApp,
  },
  {
    keyword: 'app-settings',
    routeRegex: RouteRegexes.newSettings.apps,
    hasNoChildren: false,
    items: APPS_SUBNAV_ITEMS,
    route: 'apps.app.appstore',
    showForStandaloneApps: true,
  },
  {
    keyword: 'data',
    routeRegex: RouteRegexes.newSettings.data,
    hasNoChildren: false,
    items: DATA_SUBNAV_ITEMS,
    route: 'apps.app.settings.data.people',
    showForStandaloneApps: true,
  },
  {
    keyword: 'helpcenter',
    routeRegex: RouteRegexes.newSettings.helpcenter,
    hasNoChildren: false,
    items: HELPCENTER_SUBNAV_ITEMS,
    route: 'apps.app.settings.helpcenter.workspace-helpcenter',
    showForStandaloneApps: false,
  },
  {
    keyword: 'proactive-support',
    routeRegex: RouteRegexes.newSettings.proactive,
    hasNoChildren: false,
    items: PROACTIVE_SUBNAV_ITEMS,
    route: 'apps.app.settings.proactive-support.subscriptions',
    showForStandaloneApps: false,
  },
  {
    keyword: 'personal',
    routeRegex: RouteRegexes.newSettings.personal,
    hasNoChildren: false,
    items: PERSONAL_SUBNAV_ITEMS,
    route: 'apps.app.settings.personal.details',
    showForStandaloneApps: false,
  },
];

export const STANDALONE_FALLBACK_SETTING_ROUTE: Record<string, string> = {
  'apps.app.settings.general': 'apps.app.settings.workspace.general',
  'apps.app.settings.security': 'apps.app.settings.workspace.security',
  'apps.app.settings.office-hours': 'apps.app.settings.workspace.office-hours',
  'apps.app.settings.data.people': 'apps.app.settings.standalone.data.people',
  'apps.app.settings.people-data': 'apps.app.settings.standalone.data.people',
  'apps.app.settings.data.companies': 'apps.app.settings.standalone.data.companies',
  'apps.app.settings.company-data': 'apps.app.settings.standalone.data.companies',
  'apps.app.settings.tags-new': 'apps.app.settings.data.tags',
  'apps.app.settings.subscription.billing.manage-usage': 'apps.app.settings.subscription.usage',
  'apps.app.settings.custom-authentication-tokens': 'apps.app.settings.app-settings.authentication',
};

export const ACCESSIBLE_STANDALONE_SETTING_ROUTES_VIA_DIRECT_LINK: string[] = [
  'apps.app.settings.subscription.billing.summary.index',
  'apps.app.settings.subscription.billing.current-billing-period-charges',
  'apps.app.settings.subscription.billing.settings.index',
  'apps.app.settings.subscription.billing.invoices.index',
  'apps.app.settings.subscription.billing.invoices.invoice',
  'apps.app.settings.workspace.teammates.index',
  'apps.app.settings.workspace.teammates.teammate.remove',
  'apps.app.settings.channels.messenger.index',
  'apps.app.settings.channels.messenger.mobile-sdk',
  'apps.app.settings.channels.messenger.conversations',
  'apps.app.settings.channels.messenger.general',
  'apps.app.settings.app-settings.authentication.index',
  'apps.app.settings.app-settings.authentication.new',
  'apps.app.settings.app-settings.authentication.edit',
  'apps.app.settings.app-settings.custom-actions.index',
  'apps.app.settings.app-settings.custom-actions.custom-action',
  'apps.app.settings.personal.details',
];

export function accessibleSettingNavItemRoutes(app: $TSFixMe, customer: $TSFixMe) {
  return SETTINGS_NAV_ITEMS.flatMap((item) =>
    item.items.filter((item) => {
      if (app.canUseStandalone && !item.showForStandaloneApps) {
        return false;
      }

      if (item.shouldOnlyShowForStandaloneApps && !app.canUseStandalone) {
        return false;
      }

      if (item.hideOnFeatureFlag) {
        return !app.canUseFeature(item.hideOnFeatureFlag);
      }
      if (item.showOnFeatureFlag) {
        return app.canUseFeature(item.showOnFeatureFlag);
      }
      return (
        (item.customDisplayCondition?.(app) && (item.navigableCondition?.(customer) ?? true)) ??
        true
      );
    }),
  ).map((item) => item.route);
}
