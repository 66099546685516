/* import __COLOCATED_TEMPLATE__ from './guide-step-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';
import checkEmailIsPersonal from 'embercom/utils/check-email-domain';
import type IntlService from 'embercom/services/intl';

interface Args {
  step: any;
  stepAccordion: any;
  isLastStep: boolean;
  currentlyOpenStepId?: string;
}

interface Signature {
  Args: Args;
  Element: never;
}
export default class GuideStepComponent extends Component<Signature> {
  @service appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service router: $TSFixMe;
  @service permissionsService: $TSFixMe;
  @service onboardingHomeService: $TSFixMe;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare intl: IntlService;
  @tracked showModal = false;
  @tracked isWorkEmail = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.setIsWorkEmail();
  }

  async setIsWorkEmail() {
    let personal_email_domain = await checkEmailIsPersonal(
      this.app.currentAdmin.email.split('@')[1],
    );
    this.isWorkEmail = !personal_email_domain;
  }

  get app() {
    return this.appService.app;
  }

  get identifier() {
    return this.args.step?.identifier;
  }

  get translationsPrefix(): string {
    if (this.identifier === 'fin_sa_onboarding_connect_channels_and_turn_fin_live') {
      if (this.isZendeskPlatform) {
        return `onboarding.getting-started.steps.${this.identifier}.zendesk`;
      } else if (this.isSalesforcePlatform) {
        return `onboarding.getting-started.steps.${this.identifier}.salesforce`;
      } else {
        return `onboarding.getting-started.steps.${this.identifier}.generic`;
      }
    }
    return `onboarding.getting-started.steps.${this.identifier}`;
  }

  get descriptionTranslationKey(): string {
    if (
      this.identifier === 'fin_sa_onboarding_add_support_content' &&
      !this.isZendeskPlatform &&
      !this.isSalesforcePlatform
    ) {
      return `${this.translationsPrefix}.generic-description`;
    }
    return `${this.translationsPrefix}.description`;
  }

  get stepData() {
    return this.args.step?.stepData;
  }

  get isStepDisabled() {
    return this.identifier === this.args.currentlyOpenStepId;
  }

  get embeddedUrl() {
    if (this.identifier === 'great_guidance_setup_messenger') {
      return `/a/apps/${this.app.id}/settings`;
    }
    return undefined;
  }

  @action
  primaryClickAction() {
    if (this.stepData.primaryAction) {
      this.greatGuidanceService.nextStepId = this.args.step.identifier;
      this.intercomEventService.trackAnalyticsEvent({
        ...this.args.step.analyticsMetadata,
        action: 'clicked',
        context: 'getting_started_homepage',
        object: `${this.identifier}-cta`,
        section: 'onboarding.getting-started',
        place: 'onboarding.getting-started',
        step_state: this.args.step?.state,
      });

      if (this.args.step.identifier === 'great_guidance_invite_teammates' && this.app.onPricing5) {
        if (!this.permissionsService.currentAdminCan('can_manage_teammates')) {
          this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
            'can_manage_teammates',
          );
        } else {
          this.showModal = true;
        }
      } else {
        this.stepData.primaryAction(this.router, this.args.step, this.app);
      }
    }
  }

  @action
  async closeQuickInviteModal() {
    this.showModal = false;
    await this.onboardingHomeService.loadGuide();
  }

  @action
  triggerAnalyticsEventForArticles() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'getting_started_homepage',
      object: `help_article_link`,
      article_id: this.stepData.articleId,
      step_identifier: this.identifier,
      section: 'onboarding.getting-started',
      place: 'onboarding.getting-started',
    });
  }

  get isZendeskPlatform() {
    return this.appService.app.hasStandalonePlatform('zendesk');
  }

  get isSalesforcePlatform() {
    return this.appService.app.hasStandalonePlatform('salesforce');
  }

  get platform() {
    if (this.isZendeskPlatform) {
      return this.intl.t('onboarding.getting-started.platforms.zendesk.name');
    } else if (this.isSalesforcePlatform) {
      return this.intl.t('onboarding.getting-started.platforms.salesforce.name');
    }
    return '';
  }

  get moreInfoLinkLabel() {
    if (this.isZendeskPlatform) {
      if (this.identifier === 'fin_sa_onboarding_add_support_content') {
        return this.intl.t('onboarding.getting-started.platforms.zendesk.article-sync-zendesk');
      }
      if (this.identifier === 'fin_sa_onboarding_connect_channels_and_turn_fin_live') {
        return this.intl.t(
          'onboarding.getting-started.platforms.zendesk.how-fin-integrates-zendesk',
        );
      }
    }
    return this.intl.t(`onboarding.getting-started.steps.${this.identifier}.more-info`);
  }

  get moreInfoArticleId() {
    if (this.isZendeskPlatform) {
      if (this.identifier === 'fin_sa_onboarding_add_support_content') {
        return 10617194;
      }
      if (this.identifier === 'fin_sa_onboarding_connect_channels_and_turn_fin_live') {
        return 10614168;
      }
    }
    return this.stepData.articleId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::Common::GuideStepComponent': typeof GuideStepComponent;
  }
}
