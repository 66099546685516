/* import __COLOCATED_TEMPLATE__ from './authentication-methods.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { AUTH_METHODS } from 'embercom/components/new-settings/workspace/security/general';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
interface AuthenticationMethodsArgs {
  settings: any;
  samlAccount: any;
  showAllErrors: boolean;
  scimSettings: any;
  onProvisioningMethodUpdate: (method: string) => void;
  samlBaseUrl: string;
}

export default class AuthenticationMethodsComponent extends Component<AuthenticationMethodsArgs> {
  @service intl!: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: Store;

  get emailPasswordEnabled(): boolean {
    return (
      this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.emailPassword) ||
      this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.twoFa)
    );
  }

  get twoFARequired(): boolean {
    return (
      this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.twoFa) &&
      !this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.emailPassword)
    );
  }

  set twoFARequired(value: boolean) {
    let newMethods = new Set<string>(this.args.settings.authorizedSignInMethods);

    if (value) {
      newMethods.add(AUTH_METHODS.twoFa);
      newMethods.delete(AUTH_METHODS.emailPassword);
    } else {
      newMethods.add(AUTH_METHODS.emailPassword);
    }

    this.args.settings.set('authorizedSignInMethods', Array.from(newMethods));
  }

  get googleSignInEnabled(): boolean {
    return this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.google);
  }

  get samlEnabled(): boolean {
    return this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.saml);
  }

  get canEnableSaml() {
    return this.appService.app.canUseSamlSso;
  }

  get isLastEnabledMethod() {
    let enabledMethods = [
      this.emailPasswordEnabled,
      this.googleSignInEnabled,
      this.samlEnabled,
    ].filter(Boolean);

    return enabledMethods.length === 1;
  }

  isMethodEnabled(method: string): boolean {
    return this.args.settings.authorizedSignInMethods.includes(method);
  }

  private updateEmailPasswordMethods(newMethods: Set<string>): Set<string> {
    if (newMethods.has(AUTH_METHODS.emailPassword) || newMethods.has(AUTH_METHODS.twoFa)) {
      newMethods.delete(AUTH_METHODS.emailPassword);
      newMethods.delete(AUTH_METHODS.twoFa);
    } else {
      newMethods.add(AUTH_METHODS.emailPassword);
      newMethods.add(AUTH_METHODS.twoFa);
    }

    return newMethods;
  }

  @action
  updateAuthMethods(method: string): void {
    if (this.isMethodEnabled(method) && this.isLastEnabledMethod) {
      return;
    }

    if (method === AUTH_METHODS.saml && !this.args.samlAccount) {
      let { id } = this.appService.app;
      this.args.settings.set(
        'samlAccount',
        this.store.createRecord('saml-account', { id, name: id }),
      );
    }

    let newMethods = new Set<string>(this.args.settings.authorizedSignInMethods);
    if (method === AUTH_METHODS.emailPassword) {
      newMethods = this.updateEmailPasswordMethods(newMethods);
    } else {
      newMethods.has(method) ? newMethods.delete(method) : newMethods.add(method);
    }

    this.args.settings.authorizedSignInMethods = Array.from(newMethods);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::AuthenticationMethods': typeof AuthenticationMethodsComponent;
    'new-settings/workspace/security/authentication-methods': typeof AuthenticationMethodsComponent;
  }
}
