/* import __COLOCATED_TEMPLATE__ from './collapsed.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import { type Step } from 'embercom/objects/onboarding/checklist/step';

interface Args {
  completedSteps: number;
  totalSteps: number;
  nextStep: Step | undefined;
}
export default class CollapsedChecklist extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get isZendeskPlatform() {
    return this.appService.app?.hasStandalonePlatform('zendesk');
  }

  get isSalesforcePlatform() {
    return this.appService.app?.hasStandalonePlatform('salesforce');
  }

  get platform() {
    if (this.isZendeskPlatform) {
      return this.intl.t('onboarding.getting-started.platforms.zendesk.name');
    } else if (this.isSalesforcePlatform) {
      return this.intl.t('onboarding.getting-started.platforms.salesforce.name');
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::Checklist::Collapsed': typeof CollapsedChecklist;
  }
}
