/* import __COLOCATED_TEMPLATE__ from './phone-office-hours.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: any;
    ruleset: $TSFixMe;
    partialSave: () => Promise<void>;
  };
}

const OFFICE_HOURS_SCHEDULE_ATTRIBUTE = 'office_hours.schedule';

export default class PhoneOfficeHours extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked officeHoursScheduleComparison: 'in' | 'nin' | 'anytime' = 'anytime';
  @tracked officeHoursSchedules: any[] = [];
  @tracked selectedOfficeHoursSchedule: any;
  @tracked officeHoursOptions = [
    {
      value: 'anytime',
      label: 'operator.fin.setup.tabs.setup-and-go-live.phone.office-hours.anytime',
    },
    {
      value: 'in',
      label: 'operator.fin.setup.tabs.setup-and-go-live.phone.office-hours.in-office-hours',
    },
    {
      value: 'nin',
      label: 'operator.fin.setup.tabs.setup-and-go-live.phone.office-hours.out-of-office-hours',
    },
  ];

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.loadOfficeHoursSchedules();
  }

  get savingDisabled() {
    return !this.officeHoursSchedules?.length || this.args.ruleset.isSaving;
  }

  get errorMessage() {
    return !this.officeHoursSchedules?.length
      ? this.intl.t(
          'operator.fin.setup.tabs.setup-and-go-live.phone.office-hours.error-no-office-hours',
        )
      : undefined;
  }

  get predicateGroup() {
    return this.args.ruleset.predicateGroup;
  }

  get predicates() {
    return this.predicateGroup.basePredicates;
  }

  get officeHoursSchedulePredicate() {
    return this.predicates.find((p: any) => p.attribute === OFFICE_HOURS_SCHEDULE_ATTRIBUTE);
  }

  get savedOfficeHoursScheduleComparison() {
    return this.officeHoursSchedulePredicate?.comparison || 'anytime';
  }

  get savedOfficeHoursSchedule() {
    return this.officeHoursSchedulePredicate?.value;
  }

  get activeOfficeHoursScheduleTime() {
    if (this.savedOfficeHoursScheduleComparison === 'anytime') {
      return this.intl.t('operator.fin.setup.tabs.setup-and-go-live.phone.office-hours.anytime');
    }

    let schedule = this.officeHoursSchedules.find(
      (schedule) => schedule.value === this.savedOfficeHoursSchedule,
    )?.text;

    let label =
      this.savedOfficeHoursScheduleComparison === 'in'
        ? 'operator.fin.setup.tabs.setup-and-go-live.phone.office-hours.in-office-hours-label'
        : 'operator.fin.setup.tabs.setup-and-go-live.phone.office-hours.out-of-office-hours-label';

    return this.intl.t(label, {
      schedule,
    });
  }

  @action
  resetPredicate() {
    this.officeHoursScheduleComparison = this.savedOfficeHoursScheduleComparison;
  }

  async loadOfficeHoursSchedules() {
    let officeHoursSchedules = (await this.store.findAll('office-hours-schedule')).toArray();

    this.officeHoursSchedules = officeHoursSchedules.map((schedule) => ({
      value: schedule.id,
      text: schedule.name,
      isDefault: schedule.isDefault,
    }));

    this.officeHoursScheduleComparison = this.savedOfficeHoursScheduleComparison;
    this.selectedOfficeHoursSchedule =
      this.savedOfficeHoursSchedule || this.getDefaultOfficeHoursSchedule()?.value;
  }

  getDefaultOfficeHoursSchedule() {
    return this.officeHoursSchedules.find((schedule) => schedule.isDefault);
  }

  @action
  updateOfficeHoursSchedule(value: string) {
    this.selectedOfficeHoursSchedule = value;
  }

  @action
  async updateOfficeHours() {
    if (this.savingDisabled) {
      return;
    }

    let existingPredicate = this.officeHoursSchedulePredicate;

    if (!this.officeHoursScheduleComparison || this.officeHoursScheduleComparison === 'anytime') {
      if (existingPredicate) {
        this.predicateGroup.remove(existingPredicate);
      }
      await this.args.partialSave();
      return;
    }

    if (existingPredicate) {
      existingPredicate.comparison = this.officeHoursScheduleComparison;
      existingPredicate.changeValue(this.selectedOfficeHoursSchedule);
      await this.args.partialSave();
      return;
    }

    this.predicateGroup.add({
      attribute: 'office_hours.schedule',
      type: 'office_hours_schedule',
      comparison: this.officeHoursScheduleComparison,
      value: this.selectedOfficeHoursSchedule,
    });

    await this.args.partialSave();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhoWillTalkToFin::PhoneOfficeHours': typeof PhoneOfficeHours;
  }
}
