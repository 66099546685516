/* import __COLOCATED_TEMPLATE__ from './ai-disclosure-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    fromWorkflows: boolean;
    finWorkflowCount: number;
  };
}

const AiDisclosureBanner = templateOnlyComponent<Signature>();
export default AiDisclosureBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Common::AiDisclosureBanner': typeof AiDisclosureBanner;
  }
}
