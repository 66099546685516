/* import __COLOCATED_TEMPLATE__ from './ai-disclosure-stamp.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

const AiDisclosureStamp = templateOnlyComponent();
export default AiDisclosureStamp;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Common::AiDisclosureStamp': typeof AiDisclosureStamp;
  }
}
